"use client";
import { VerticalContainer } from "@/components/layout/vertical-container";
import { EventCard } from "@/components/ui/event-card";
import { Swiper } from "@/components/ui/swiper";
import { Text } from "@/components/ui/text";
import { UserContext } from "@/contexts/user-context";
import {
  type BookableEventFragment,
  type FilterInput,
  useBookableEventsSuspenseQuery,
} from "@/generated/client.generated";
import dayjs from "dayjs";
import { useContext } from "react";

interface BookingNextEventsResultsSwiperProps {
  currentFilters: ReadonlyArray<FilterInput>;
  take: number;
  isSidebar?: boolean;
}

export function BookingNextEventsResultsSwiper({
  currentFilters,
  take,
  isSidebar,
}: BookingNextEventsResultsSwiperProps) {
  const filters = [...currentFilters];

  // get bookable events
  const { data, error } = useBookableEventsSuspenseQuery({
    variables: {
      filter: filters,
      skip: 0,
      take: take,
    },
  });
  const { myBookedEvents } = useContext(UserContext);

  if (error) {
    throw error;
  }
  const bookableEvents = [...data.BookableEvents].sort((a: BookableEventFragment, b: BookableEventFragment) =>
    dayjs(a.duration.start).isBefore(dayjs(b.duration.start)) ? -1 : 1,
  );

  if (bookableEvents.length === 0) {
    return (
      <VerticalContainer horizontalPlacement="center">
        <Text style="italic" value="Ingen kommende arrangement" />
      </VerticalContainer>
    );
  }

  if (bookableEvents.length === 1) {
    return (
      <EventCard
        key={bookableEvents[0].id}
        parts={bookableEvents[0]}
        status={
          myBookedEvents.find((myBookedEvent) => myBookedEvent.bookableEvent?.id === bookableEvents[0].id)
            ? "Booked"
            : "Bookable"
        }
      />
    );
  }
  return (
    <Swiper
      isSidebar={isSidebar}
      items={bookableEvents.map((bookableEvent) => (
        <EventCard
          key={bookableEvent.id}
          parts={bookableEvent}
          status={
            myBookedEvents.find((myBookedEvent) => myBookedEvent.bookableEvent?.id === bookableEvent.id)
              ? "Booked"
              : "Bookable"
          }
        />
      ))}
    />
  );
}
